export const limit = "[0...24]"

export const orderingAndLimit = `order(featured desc, dateTime(publishedAt) desc)${limit}`

export const fields = `{
    title,
    description,
    _id,
    publishedAt,
    slug,
    featured,
    brand->{logo, name, slug},
    career->{name, slug},
    jobType->{name, slug},
    country->{name, code, slug},
    city->{name, shortName, slug}
  }`

export const allFields = `{
    ...,
    brand->,
    career->{name, slug},
    jobType->{name, slug},
    country->{name, code, slug},
    city->{name, shortName, slug},
    industry->{name, slug}
  }`
